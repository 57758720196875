
import { FormController, validationRules } from '@icepanel/app-form'
import { getAuth, getRedirectResult, SAMLAuthProvider, User } from 'firebase/auth'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as firebase from '@/plugins/firebase'

import * as analytics from '../helpers/analytics'
import * as authListener from '../helpers/auth-listener'
import { UserModule } from '../store'

@Component({
  name: 'UserLoginSAML'
})
export default class extends Vue {
  userModule = getModule(UserModule, this.$store)

  formInteracted = false

  get docsUrl () {
    return 'https://docs.icepanel.io'
  }

  formController = new FormController({
    initialModel: {
      email: typeof this.$route.query.email === 'string' ? this.$route.query.email : '',
      user: undefined as User | undefined
    },
    validationRules: {
      email: [
        ...validationRules.exists,
        ...validationRules.email
      ]
    }
  })

  async created () {
    this.formController.submitHandler = async model => {
      const email = model.email.toLowerCase()
      const [, domain] = email.split('@')

      if (!model.user) {
        await this.$replaceQuery({
          email
        })
        await this.userModule.userEmailPrepare({
          email
        })

        authListener.initialize()

        await this.userModule.firebaseLogin({
          app: firebase.app,
          redirectProvider: new SAMLAuthProvider(`saml.${domain}`)
        })
      } else if (model.user.email && model.email && model.user.email.toLowerCase() !== email) {
        this.formController.model.user = undefined
        throw new Error('This account did not match the email address provided')
      } else if (this.userModule.firebaseUser && !this.userModule.user) {
        await this.$router.push({
          name: 'user-register-saml',
          query: {
            email
          }
        })
      } else if (this.userModule.firebaseUser && this.userModule.user) {
        authListener.initialize()

        await this.userModule.userLogin({
          app: firebase.app,
          user: this.userModule.firebaseUser
        })

        analytics.userLogin.track(this, {
          userAuthProvider: 'saml'
        })

        this.$router.push({
          name: 'organizations'
        })
      }
    }

    try {
      const userCredential = await getRedirectResult(getAuth(firebase.app))
      if (userCredential) {
        this.formController.model.user = userCredential.user
        this.formController.submit()
      }
    } catch (err: any) {
      console.error(err)
      this.formController.errorMessage = err.message
    }

    if (this.$route.query.login) {
      this.formController.submit()
    }
  }

  mounted () {
    analytics.userLoginScreen.track(this, {
      userAuthProvider: 'saml'
    })
  }

  formInteraction () {
    if (!this.formInteracted) {
      this.formInteracted = true

      analytics.userLoginForm.track(this, {
        userAuthProvider: 'saml'
      })
      analytics.userLogin.time()
    }
  }
}
